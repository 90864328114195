<template>
  <div class="container" ref="wrapper">
    <van-search
      v-model="page.filterData.title"
      @search="onSearch"
      placeholder="请输入搜索关键词"
    />

    <van-cell>
      <van-col
        :span="item2.text.length == 2 ? 5 : 6"
        v-for="item2 in subTypeOption2"
        :key="item2.value.toString()"
      >
        <van-popover v-model="item2.showPopover" trigger="click">
          <div style="wight: 100%">
            <van-cell
              v-for="item in subTypeOption"
              :key="item.value.toString()"
            >
              <van-col :span="24">
                <van-tag
                  round
                  size="large"
                  :color="
                    page.filterData.subTypeIds == item.value && page.filterData.subTypeIds2 == item2.value  ? '#9d1d22' : ''
                  "
                  @click="changesubTypeIds(item.value, item2.value)"
                  >{{ item.text }}</van-tag
                >
              </van-col>
            </van-cell>
          </div>
          <template #reference>
            <van-tag
              round
              size="large"
              :color="
                page.filterData.subTypeIds2 == item2.value ? '#9d1d22' : ''
              "
              class="vantag_title"
              >{{ item2.text }} &nbsp; &nbsp;</van-tag
            >
          </template>
        </van-popover>
      </van-col>
    </van-cell>

    <van-cell>
      <van-col span="5">
        <van-tag
          round
          size="large"
          :color="page.filterData.isLock == null ? '#9d1d22' : ''"
          @click="changeIsLock(null)"
          >全部</van-tag
        >
      </van-col>
      <van-col span="6">
        <van-tag
          round
          size="large"
          :color="page.filterData.isLock == false ? '#9d1d22' : ''"
          @click="changeIsLock(false)"
          >未预定</van-tag
        >
      </van-col>
      <van-col span="6">
        <van-tag
          round
          size="large"
          :color="page.filterData.isLock == true ? '#9d1d22' : ''"
          @click="changeIsLock(true)"
          >已预定</van-tag
        >
      </van-col>
    </van-cell>
    <van-cell
      v-if="page.filterData.productId != '00000000-0000-0000-0000-000000000000'"
    >
      <van-col span="24">
        <van-tag
          v-if="
            page.filterData.productId != '00000000-0000-0000-0000-000000000000'
          "
          closeable
          round
          size="large"
          color="#9d1d22"
          @close="closeproduct"
        >
          {{ productName }}
        </van-tag>
      </van-col>
    </van-cell>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
        <div class="van-clearfix">
          <div
            class="layout_wrapper"
            style="
              float: left;
              width: 50%;
              text-align: center;
              background-color: #f8f8f8;
              overflow: hidden;
            "
            v-for="item in articles"
            :key="item.id.toString()"
            @click="toArticleDetail(item)"
          >
            <div class="product-layout__item">
              <van-image
                lazy-load
                class="w100"
                fit="contain"
                style="max-height: 200px"
                :src="item.imageUrl"
              />

              <div style="height: 42px; overflow: hidden">
                <h3
                  v-if="!item.isLock"
                  style="display: inline-block; height: 21px"
                >
                  {{ item.title }}
                </h3>
                <s v-else>
                  <h3 style="color:#b8abab display: inline-block; height: 21px">
                    (已预定){{ item.title }}
                  </h3></s
                >
              </div>
              <van-tag
                @click.stop="toSubType(item)"
                size="large"
                color="rgb(255 242 219)"
                text-color="#9d1d22"
                >{{ item.subTypeString }}
              </van-tag>
            </div>
          </div>
        </div>

        <!-- <van-cell-group style="background-color: #ececed;">
          
          <van-cell
            v-for="item in articles"
            :key="item.id.toString()"
            @click="toArticleDetail(item)"
            class="item"
          >
          <div >
              
            <van-image
                lazy-load
                class="w100"
                fit="fill"
                style="max-height: 80px;max-height: 380px"
                :src="item.imageUrl"
              >
              </van-image>
         <div v-if="item.isLock"  style="background-color: gray;
    color: white;
    width: 90%;
    height: 80px;
    line-height: 80px;
    text-align: center;
    margin-left: 20%;
    margin-top: -100px;
    position: absolute;
    opacity: 0.8;
    transform: rotate(45deg);
    font-size: 50px;">已预定</div>
  </div>
            <div style="weight:100%">
              <div
                style="display: table-cell; vertical-align: middle"
                class="zntitle van-multi-ellipsis--l3"
              >
                <h3 style="display: inline-block">{{ item.title }}</h3>
              </div>
              <div
                style="float: left"
                v-if="
                  item.productName != undefined && item.productName.length > 0
                "
              >
                <van-tag
                  @click.stop="toProduct(item)"
                  size="large"
                  color="rgb(255 242 219)"
                  text-color="#9d1d22"
                  >{{ item.productName }}
                </van-tag>
              </div>
                <div
                style="float: left"
                v-if="
                  item.subTypeString != undefined && item.subTypeString.length > 0
                "
              >
                <van-tag
                  @click.stop="toSubType(item)"
                  size="large"
                  color="rgb(255 242 219)"
                  text-color="#9d1d22"
                  >{{ item.subTypeString }}
                </van-tag>
              </div>
              <div style="float: right">{{ item.creationTime }}</div>
              <div style="float: right; margin-right: 13px">
                浏览{{ item.readCount }}人次
              </div>
            </div>
          </van-cell>
        </van-cell-group> -->
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getArticleList, getArticleTypeList } from "@/api/article";
import { getArticleType } from "@/utils/datetype";
import { getProductName } from "@/api/product";
import { Toast } from "vant";
//const key = "zn-history";
export default {
  name: "znhdarticle",
  data() {
    return {
      scroll: 0,
      refreshing: false,
      productName: "",
      subTypeOption: [],
      subTypeOption2: [],
      type: 100,
      title: "",
      keyword: "",
      upLoading: false,
      finished: false,
      articles: [],
      page: {
        current: 1,
        pageSize: 10,
        sorterType: 0,
        filterData: {
          subTypeIds: "",
          subTypeIds2: "",
          title: "",
          typeId: 100,
          isLock: null,
          productId: "00000000-0000-0000-0000-000000000000",
        },
      },
      isWeixin:
        navigator.userAgent.toLowerCase().indexOf("micromessenger") > -1,
      isMiniprogram: false,
    };
  },
  components: {},
  activated() {
    //  window.scrollTo(0, this.scroll);
    //  alert( this.$refs.wrapper.scrollTop);
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    this.initData();
  },
  mounted() {},

  methods: {
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    async initData() {
      this.page.sorterType = 0;
      let type = this.$route.query.type;
      this.type = type;
      if (this.type == 106) {
        this.page.sorterType = 1;
      }

      if (this.isWeixin) {
        try {
          //var jsConfig=await GetAppJsConfig(encodeURIComponent(location.href.split("#")[0]));

          let win = window;
          if (win.wx) {
            // win.wx.config({
            //   debug: false,
            //   appId: jsConfig.appId,
            //   timestamp: jsConfig.timestamp,
            //   nonceStr: jsConfig.nonceStr,
            //   signature: jsConfig.signature,
            //   jsApiList: [
            //     "checkJsApi",

            //   ],
            // });
            win.wx.ready(() => {
              console.log("微信jssdk加载成功!!!!");
            });
            win.wx.error((res) => {
              console.log("微信jssdk加载失败!!!" + res.errMsg);
            });

            win.wx.miniProgram.getEnv((res) => {
              console.log(res);
              if (res.miniprogram) {
                console.log("在小程序里！！！");
              } else {
                console.log("不在小程序！！！");
              }
            });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log("不在微信中");
      }

      this.title = getArticleType(type);
      this.page.filterData.typeId = type;

      let productId = this.$route.query.productId;
      if (productId === undefined) {
        productId = "00000000-0000-0000-0000-000000000000";
      }
      this.page.filterData.productId = productId;

      if (productId != "00000000-0000-0000-0000-000000000000") {
        let nameReuslt = await getProductName(productId);
        this.productName = nameReuslt.data.data;
      }

      let subTypeList = await getArticleTypeList({
        current: 1,
        pageSize: 100,
        filterData: {
          parentID: this.type,
        },
      });
      if (subTypeList.data.data.length > 0) {
        this.subTypeOption = [{ text: "全部", value: "" }];
        subTypeList.data.data.map((x) =>
          this.subTypeOption.push({ text: x.typeName, value: x.id.toString() })
        );
      }

      this.subTypeOption2 = [
        
      ];
      if(this.type==300){

        let subTypeList2 = await getArticleTypeList({
        current: 1,
        pageSize: 100,
        filterData: {
          parentID: 301,
        },
      });
      if (subTypeList2.data.data.length > 0) {
        this.subTypeOption2 = [{ text: "全部", value: "",showPopover:false }];
        subTypeList2.data.data.map((x) =>
          this.subTypeOption2.push({ text: x.typeName, value: x.id.toString(),showPopover:false })
        );
      }
      }

    },
    toProduct(item) {
      if (this.page.filterData.productId != item.productId) {
        this.productName = item.productName;
        this.page.filterData.productId = item.productId;
        this.onSearch();
      }
    },
    toSubType(item) {
      //page.filterData.subTypeIds
      if (this.page.filterData.subTypeIds != item.subTypeIds) {
        this.page.filterData.subTypeIds = item.subTypeIds;
        this.onSearch();
      }
    },
    closeproduct() {
      this.page.filterData.productId = "00000000-0000-0000-0000-000000000000";
      this.onSearch();
    },
    onSearch() {
      this.finished = false;
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
    toArticleDetail(item) {
      let wrapperScrollTop = this.$refs.wrapper.scrollTop;
      //存储 scrollTop 的值
      this.scroll = wrapperScrollTop;
      if (!item.isLockShow) {
        Toast("已预订,仅限预定人查看");
        return;
      }

      this.$router.push({
        path: "/hd/detail",
        query: { para: item.id, type: this.page.filterData.typeId },
      });
    },
    async onLoad() {
      this.upLoading = true;
      let aresult = await getArticleList({ ...this.page }); //({ ...this.page })
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
    changeSorterType(typeid) {
      if (this.page.sorterType != typeid) {
        this.page.sorterType = typeid;
        this.onSearch();
      }
    },
    changesubTypeIds(typeid, typeid2) {
      var ifsearch = false;
      if (this.page.filterData.subTypeIds2 != typeid2) {
        this.page.filterData.subTypeIds2 = typeid2;
        ifsearch = true;
      }
      if (this.page.filterData.subTypeIds != typeid) {
        this.page.filterData.subTypeIds = typeid;
        ifsearch = true;
      }
      if (ifsearch) {
        this.onSearch();
      }
    },
    changeIsLock(lock) {
      if (this.page.filterData.isLock != lock) {
        this.page.filterData.isLock = lock;
        this.onSearch();
      }
    },
    changeIsLockNull() {
      if (this.page.filterData.isLock != null) {
        this.page.filterData.isLock = null;
        this.onSearch();
      }
    },
  },
};
</script>

<style lang="less" scoped>
// .w100 {
//   /deep/ img {
//     width: 100%;
//     max-height: 380px;
//   }
// }

.w100 {
  /deep/ img {
    width: 100%;
    height: 200px;
    max-height: 200px;
  }
}
.vantag_title{
  padding-right: 13px;
}
.vantag_title::after {
  position: absolute;
  top: 50%;
  right: 3px;
  margin-top: -5px;
  border: 3px solid;
  border-color: transparent transparent #dcdee0 #dcdee0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  opacity: 0.8;
  content: "";
}
.product-layout__item {
  position: relative;
  display: block;
  box-sizing: border-box;
  min-height: 50px;
  overflow: hidden;
  color: #333;
  background-color: #fff;
  margin: 2px;
}
.item {
  border: 12px solid #ececed;
  background-color: #fff;
  border-radius: 27px;
  margin-bottom: 10px;
}
.zntitle {
  height: 80px;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
