<template>
    <div>
       <van-popup
            v-model="showProduct"
            
            position="center"
            :style="{ height: '90%' ,width:'95%' }"
          >
           <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >保密协议加载中...</van-loading
    >
    <div v-if="loading == false">
         <div class="acontent" v-html="articles.detailsHtml"></div>

 <van-form @submit="onSubmit">
        <div>
          <van-field
           v-model="signPara.signName"
            label="您的姓名"
            placeholder="请输您的姓名"
            required
            :rules="[{ required: true, message: '' }]"
          />
          <van-field
           v-model="signPara.signDepartment"
            label="您的部门"
            placeholder="请输您的部门"
            required
            :rules="[{ required: true, message: '' }]"
          />
          
        </div>
        <div>
          <div style="margin: 16px">
            <van-button round block type="info" native-type="submit"
              >确定</van-button
            >
          </div>
        </div>
      </van-form>
    </div>
          </van-popup>
    </div>
</template>

<script>
import { getArticleInfo } from "@/api/article";
import { AddHDSign } from "@/api/HDOrder";
import { Toast } from "vant";

export default {
  props: {
  },
   watch:{
  

    
 },
  data() {
    return {
        showProduct:true,
        articles:{},
        loading: true,
        signPara: {
        signName: "",
        signDepartment: "",
      },
    }
  },
  created() {
      this.onLoad();
  },
  methods: {
     async onLoad() {
      let para ='345b83cf-0447-4084-ab0e-9dfc758f394b';// '345b83cf-0447-4084-ab0e-9dfc758f394b';//CDCBE1EB-DE6D-4C7E-ADAB-0072612DCD62
      let aresult = await getArticleInfo(para); //({ ...this.page })
      this.articles = aresult.data.data;
      this.loading=false;
    
    },
   async onSubmit(){

           const aresult = await AddHDSign(this.signPara);
      if (aresult.data.success) {
          Toast("保密协议确认成功");
    
      this.showProduct=false;

    }
    }
  },
};
</script>
<style lang="less" scoped>

.loading {
  text-align: center;
}
.acontent{
    margin: 12px;
}
.reply-container {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  overflow: auto;
  background: #f5f5f5;
  z-index: 2000;
  .submit {
    font-size: 12px;
    color: #fff;
  }
}

.reply_dialog {
  height: 100%;
  max-height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  .van-action-sheet__header {
    background: #3296fa;
    color: #fff;
    .van-icon-close {
      color: #fff;
    }
  }
  .van-action-sheet__content {
    flex: 1;
    overflow-y: auto;
    padding: 0 10px 44px;
  }
}

</style>