<template>
  <div class="container" ref="wrapper">
   

     <van-row v-if="subTypeOption.length > 0">
      <van-col span="8">
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="page.filterData.status"
            :options="subTypeOption"
            @change="onSearch"
          />
        </van-dropdown-menu>
      </van-col>
      <van-col span="16">
        <van-search
          v-model="page.filterData.itemName"
          @search="onSearch"
          placeholder="请输入搜索关键词"
        />
      </van-col>
    </van-row>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
      <van-collapse v-model="activeNames" accordion>
          <van-collapse-item
            v-for="item in articles"
            :key="item.id.toString()"
            :name="item.id"
          >
            <template #title>
              <div>
                <div>
                  <h3 v-if="item.status==1">{{ item.itemName }}</h3>
                 <s v-if="item.status==2"> <h3  style="color:#b8abab">{{ item.itemName }} (已取消)</h3>
                  </s>
                </div>

                <!-- <div style="float: left">
                    <h4>采购时间：{{ DateFromat(item.orderTime) }}</h4>
                  
                    
                  </div> -->

                <div>
                 
                
                  <!-- <h4>
                    <div>销售价格(瓶)：{{ item.price2 }}</div>
                  </h4> -->
                </div>
              </div>
            </template>
            <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
              <van-tag
          round
          size="large"
          color="#9d1d22"
          @click="toArticleDetail(item)"
          >查看创意</van-tag
        >
            <van-cell>
                 <van-col span="24"> 客户公司：{{ item.customerCompany }} </van-col>
                  <van-col span="24"> 客户姓名：{{ item.customerName }} </van-col>
              <van-col span="24"> 客户电话 ： {{ item.customerPhone }} </van-col>
              <van-col span="24"> 备注 ： {{ item.remark }} </van-col>
               <van-col span="24"> 取消原因： {{ item.cancelReason }} </van-col>
            
                          </van-cell>
          </van-collapse-item>
        </van-collapse>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { getMyHDOrderList } from "@/api/HDOrder";

export default {
  data() {
    return {
      refreshing: false,
      upLoading: false,  
      finished: false,
      articles: [],
      activeNames: "",
      subTypeOption:[{ text: "全部", value: "0" },{ text: "正常", value: "1" },{ text: "已取消", value: "2" }],
      page: {
        current: 1,
        pageSize: 10,
        sorterType: 0,
        filterData: {
          status:"0",
        },
      },
    };
  },

  created() {
  
  },
  methods: {
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
     onSearch() {
      this.finished = false;
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
     async onLoad() {
      this.upLoading = true;
      let aresult = await getMyHDOrderList({ ...this.page }); //({ ...this.page })
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
      toArticleDetail(item) {
      let wrapperScrollTop = this.$refs.wrapper.scrollTop;
      //存储 scrollTop 的值
      this.scroll = wrapperScrollTop;
      

      this.$router.push({
        path: "/hd/detail",
        query: { para: item.itemId, type: this.page.filterData.typeId },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.reply-container {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  overflow: auto;
  background: #f5f5f5;
  z-index: 2000;
  .submit {
    font-size: 12px;
    color: #fff;
  }
}

.reply_dialog {
  height: 100%;
  max-height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  .van-action-sheet__header {
    background: #3296fa;
    color: #fff;
    .van-icon-close {
      color: #fff;
    }
  }
  .van-action-sheet__content {
    flex: 1;
    overflow-y: auto;
    padding: 0 10px 44px;
  }
}
</style>